<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Daftar Bloodbags</h2>
      <div class="header d-flex justify-content-between">
        <p class="hp-p1-body align-items-center">Semua (5)</p>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary"></i>
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Search"
              class="border-left-0"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
    </b-col>
    <TableListBloodbag />
    <PaginateBloodbags />
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import PaginateBloodbags from "./components/PaginateBloodbags";
import TableListBloodbag from "./components/TableListBloodbag";

export default {
  name: "bloodbags",
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    PaginateBloodbags,
    TableListBloodbag,
  },
};
</script>

<style scoped>
/* mobile screen */
@media screen and (max-width: 576px) {
  .header {
    flex-direction: column;
  }
}
</style>
