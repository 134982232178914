<template>
  <div class="col-12">
    <b-table
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive="sm"
    >
      <template v-slot:cell(aksi)="row">
        <b-button-group v-for="(item, index) in navigate">
          <router-link :to="item.to" :key="index">
            <b-button size="sm" class="m-1" :variant="item.variant">
              <i :class="item.icon"></i>
              {{ item.name }}
            </b-button>
          </router-link>
        </b-button-group>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BButton, BIcon, BButtonGroup, BBadge } from "bootstrap-vue";

export default {
  name: "TableListDonation",
  data() {
    return {
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "no", sortable: true },
        { key: "kategoriGolonganDarah", sortable: true },
        { key: "totalStok", sortable: true },
        { key: "aksi", sortable: false },
      ],
      items: [
        {
          no: 1,
          kategoriGolonganDarah: "O+",
          totalStok: 20,
        },
        {
          no: 2,
          kategoriGolonganDarah: "O-",
          totalStok: 20,
        },
        {
          no: 3,
          kategoriGolonganDarah: "O-",
          totalStok: 20,
        },
        {
          no: 4,
          kategoriGolonganDarah: "A+",
          totalStok: 20,
        },
        {
          no: 5,
          kategoriGolonganDarah: "A-",
          totalStok: 20,
        },
        {
          no: 6,
          kategoriGolonganDarah: "B+",
          totalStok: 20,
        },
        {
          no: 7,
          kategoriGolonganDarah: "AB+",
          totalStok: 20,
        },
        {
          no: 8,
          kategoriGolonganDarah: "B+",
          totalStok: 20,
        },
      ],
      navigate: [
        {
          name: "Riwayat",
          icon: "ri-history-fill",
          variant: "success-1",
          to: "/dashboards/bloodbag",
        },
        {
          name: "Hapus",
          icon: "ri-delete-bin-5-line",
          variant: "danger",
          to: "/",
        },
      ],
    };
  },
  components: {
    BTable,
    BButton,
    BIcon,
    BButtonGroup,
    BBadge,
  },
};
</script>
